
export const RedirectAbout = (history) => {
    history.push('/sobre')
}

export const RedirectToWork = (url) => {
    window.open(url)
}

export const Impar = (number) => {
    if(number%2 === 0){
        return 'par'
    }
    return 'impar'
}
export const RedirectOpenWindow = (url) => {
    window.open(url)
}