import React from 'react'
import { RedirectOpenWindow } from '../helpers/funtions'
import { ContainerContact } from './contact-style/ContactStyled'
import db from '../db.json'

let {number, text, email, instagram, linkdIn, gitHub} = db.infoContact
const Contact = () => {
    return (
        <ContainerContact>
            <h2>Dejame un saludito <i className="fas fa-hand-peace"></i></h2>
            <i className="fab fa-whatsapp" onClick={() => {RedirectOpenWindow(`https://wa.me/${number}/?text=${text}`)}}></i>
            <a href="tel:+34678567876" target='_blank' rel="noreferrer"><i className="fas fa-phone-alt"></i></a>
            <i className="fab fa-linkedin" onClick={() => {RedirectOpenWindow(linkdIn)}}></i>
            <i className="fab fa-github" onClick={() => {RedirectOpenWindow(gitHub)}}></i>
            <a href={`mailto:${email}?Subject=${text}`} >  <i className="far fa-envelope"></i></a>
            <i className="fab fa-instagram" onClick={() => {RedirectOpenWindow(instagram)}}></i>
        </ContainerContact>
    )
}

export default Contact
