import styled from 'styled-components'

export const ContainerContact = styled.div`
    display: flex;
    flex-flow: column wrap;
    margin: 2rem;
    h2{
        text-align: center;
        margin: 2rem auto;
    }
    a, i{
        font-size: 2.5rem;
        margin: 0.5rem auto;
        cursor: pointer;
    }
`