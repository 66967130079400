import React from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import {  ContainerNav } from './nav-styled/NavStyled'
import logo from '../assets/img/logoPro.png'
import '@fortawesome/fontawesome-free/css/all.min.css';
import { RedirectAbout, RedirectOpenWindow } from '../helpers/funtions';

const Nav = () => {
    const history = useHistory();
    return (
        <ContainerNav>
        <input type="checkbox" />
        <div className='burger'></div>
        <div className='containerNav'>
            <NavLink to='/sobre' activeClassName='active'>
                Sobre Mí
            </NavLink>
            <NavLink to='/trabajo' activeClassName='active'>
                Trabajo
            </NavLink>
            <NavLink to='/estudio' activeClassName='active'>
                Estudio
            </NavLink>
            <NavLink to='/contacto' activeClassName='active'>
                Contacto
            </NavLink>
            <button
            onClick={
                () => {RedirectOpenWindow('https://drive.google.com/file/d/1T_jcxy0iMjQoCIVjh-a1MqN1A0mlT5wR/view?usp=sharing')}
            }>
                <i className="fas fa-file-download"></i>CV
            </button>
        </div>
        <div className='containerImg'>
        <img 
        src={logo} alt='logo Jack' 
        onClick={() => {RedirectAbout(history)}}
        />
        </div>
        </ContainerNav>
    )
}
export default Nav
