import styled from 'styled-components'
import { variables } from '../../variables-style/variables'

export const ContainerWork = styled.div`
    padding-bottom: 4rem;
    h2{
        text-align: center;
        margin-top: 4rem;
        margin-bottom: 5rem;
    }
`
export const ContentWork = styled.div`
    width: 100%;
    > div {
        display: flex;
        flex-flow: column wrap;
        align-self: center;
        justify-content: center;
        position: relative;
        margin-bottom: 8rem;
        .legendWork{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 80%;
            height: 30vw;
            max-width: 500px;
            max-height: 150px;
            position: absolute;
            background-color: ${variables.colorFontTwo};
            bottom: -1rem;
            cursor: pointer;
            overflow: auto;
            ::-webkit-scrollbar {
                display: none;
            }
            p{
                margin: 0;
                font-size: 3.5vw;
                padding: 0.8rem;
            }
        }
        .contentImg {
            overflow:hidden;
            img{
            width: 80vw;
            height: 60vw;
            max-width: 500px;
            max-height: 350px;
            transition: transform 1s;
            object-fit: cover;
            cursor: pointer;
            :hover{
                filter: grayscale(80%);
                -webkit-transform:scale(1.3);
                transform: scale(1.3);

            }
        }
        }
    }
    .impar{
        .legendWork{
            right: 0;
        }
    }
    .par{
        .contentImg{
            display: flex;
            justify-content: flex-end;
        }
    }
    @media screen and (min-width: 600px){
        width: 90%;
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;
        >div {
            .legendWork{
            p{
                font-size: 1rem;
            }
        }
        }
    }
`
