import React from 'react'
import { ContainerRating } from './rating-abilities-styled/RatinAbilitiesStyled'

const RatingAbilities = ({rating}) => {
return (
        <ContainerRating rating={rating}>
            <div className='divRating' title={rating}></div>
        </ContainerRating>
    )
}

export default RatingAbilities
