import React from 'react'
import { Wrapper } from './about-abilities-style/AboutAbilities'
// import './about-abilities-style/aboutAbilities.css'
import RatingAbilities from './RatingAbilities'
import db from '../db.json'

const AboutAbilities = () => {
    return (
        <Wrapper className="warpper">
        <input className="radio" id="one" name="group" type="radio" defaultChecked />
        <input className="radio" id="two" name="group" type="radio" />
        <input className="radio" id="three" name="group" type="radio" />
        <div className="tabs">
            <label className="tab" id="one-tab" htmlFor="one">Tecnologias</label>
            <label className="tab" id="two-tab" htmlFor="two">Habilidades</label>
            <label className="tab" id="three-tab" htmlFor="three">Aptitudes</label>
            <div className='hover-background'> .</div>
        </div>

        <div className="panels">
            <div className="panel" id="one-panel">
            {
                db.tecnologias.map( (ele, i) => (
                    <div key={i} className='contentPanel'>
                        <div>
                            <p className='technologyTab'>{ele.name} - {ele.yearInitial}</p>
                            <i className={ele.logo}></i>
                        </div>
                        <RatingAbilities rating={ele.rating} />
                    </div>
                ))
            }
            </div>
            <div className="panel" id="two-panel">
                {
                    db.habilidades.map((ele, i) => (
                        <div key={i}  className='contentPanel'>
                            <p className='headerTab'>{ele.name}</p>
                            <RatingAbilities rating={ele.rating}/>
                        </div>
                    ))
                }
            </div>
            <div className="panel" id="three-panel">
                {
                    db.aptitudes.map((ele, i) => (
                        <div key={i} className='contentPanel' >
                            <p className='headerTab'>{ele.name}</p>
                            <RatingAbilities rating={ele.rating}/>
                        </div>
                    ))
                }
            </div>
        </div>
        </Wrapper>
    )
}

export default AboutAbilities
