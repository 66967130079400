import React from 'react'
import { Impar, RedirectToWork } from '../helpers/funtions'
import { ContainerWork, ContentWork } from './work-styled/WorkStyled'
import db from '../db.json'

const Work = () => {
    return (
        <ContainerWork>
            <h2>Parece magia, todo funciona con código.</h2>
            <ContentWork>
            {
                db.works.map((ele, i)=> (
                    <div key={i} 
                    className={Impar(i)}>
                        <div className='contentImg'>
                            <img src={ele.img} alt={ele.name} 
                            onClick={() => {RedirectToWork(ele.url)}}/>
                        </div>
                    <div className='legendWork'>
                        <p>{ele.decription}</p>
                    </div>
                    </div>
                ))
            }
            </ContentWork>
        </ContainerWork>
    )
}

export default Work
