import styled from 'styled-components'
import { variables } from '../../variables-style/variables'


export const ContainerNav = styled.div`
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 50;
    .containerNav{
        display: flex;
        flex-flow: wrap column;
        text-align: center;
        align-items: center;
        width: 100%;
        position: fixed;
        padding-bottom: 1rem;
        transform: translateY(-200%);
        transition: transform 1s ease;
        background-color: ${variables.background};
        a{
            width: 20%;
            text-decoration: none;
            margin: 0.2rem 0;
            font-size: 17px;
        }
        .active{
            border-bottom: 2px solid ${variables.colorFont};
        }
        button{
            margin: 0 1rem ;
            cursor: pointer;
            padding: 0.5rem 1rem;
            i{
                margin-right: 0.5rem;
            }
            :hover{
                box-shadow: 0 0 20px ${variables.colorFont};
            }
        }
    }
    .containerImg{
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 1rem;
        order: -1;
        z-index: 9;
        background-color: ${variables.background};
        img{
        width: 200px; 
        cursor: pointer;
        margin: auto;
        }
    }
    input{
        width: 3rem;
        height: 3rem;
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        opacity: 0;
        z-index: 110;
        cursor: pointer;
    }
    .burger{
        position: absolute;
        top: 1.8rem;
        right: 0.5rem;
        width: 3rem;
        height: 0.5rem;
        border-radius: 1rem;
        background-color: ${variables.colorFont};
        transition: background-color 0.2s ease;
        z-index: 100;
    }
    .burger::after{
        position: fixed;
        content: '';
        top: 0.5rem;
        right: 0.5rem;
        width: 3rem;
        height: 0.5rem;
        border-radius: 1rem;
        background-color: ${variables.colorFont};
        transition: transform 0.2s ease;
        z-index: 100;
    }
    .burger::before{
        position: fixed;
        content: '';
        top: 3rem;
        right: 0.5rem;
        width: 3rem;
        height: 0.5rem;
        border-radius: 1rem;
        background-color: ${variables.colorFont};
        transition: transform 0.2s ease;
        z-index: 100;
    }
    input:checked ~ .containerNav{
        transform: translateY(83.88px);
    }
    input:checked + .burger::after{
    transform: rotate(45deg) translate(1rem, 0.8rem);
    }
    input:checked + .burger::before{
        transform: rotate(-45deg) translate( 1rem, -0.8rem);
    }
    input:checked + .burger {
        background-color: transparent;
    }
    @media screen and (min-width: 1024px){
        display: flex;
        flex-flow: row wrap;
        z-index: 200;
        background-color: ${variables.background};
        padding: 1rem;
        .containerNav{
            flex-flow: row wrap;
            justify-content: flex-end;
            width: 70%;
            transform: none;
            transition: none;
            position: initial;
            background-color: transparent;
            z-index: 0;
            a{
                width: auto;
                margin: 0 0.5rem;
            }
        }
        input{
            display: none;
        }
        .burger{
            display: none;
        }
        .containerImg{
            z-index: 0;
            background-color: transparent;
            padding: 0;
            width: 30%;
        }
    }
`