import styled from 'styled-components'
import { variables } from '../../variables-style/variables'

export const ContainerFooter = styled.div`
    display: flex;
    flex-flow: column wrap;
    width: 100%;
    padding: 1rem;
    margin-top: 5rem;
    border-top: 1px solid ${variables.colorFontTwo};
    background-color: ${variables.background};
    img{
        width: 150px;
        margin: 0.5rem auto;
        cursor: pointer
    }
    .pFooter{
        font-size: 15px;
        color: ${variables.colorFontTwo};
        margin: auto;
        background-color: ${variables.background};
        text-align: center;
    }
    .containerIcon{
        margin: 0.5rem auto;
    }
    i{
        margin: 0 0.5rem;
        font-size: 1.5rem;
        cursor: pointer;
    }
    @media screen and (min-width: 700px){
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        align-content: center;
        /* width: 80; */
        .pFooter{
            grid-column: 1/3;
        }
    }
`