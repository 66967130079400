import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './routes/Routes';

ReactDOM.render(
  <>
    <Routes/>
  </>,
  document.getElementById('root')
);

// Trabajos a editar


// {
//   "name": "DCocoa",
//   "decription": "Compra todo tipo de prodcutos derivados del cacao.",
//   "rol": "Desarrollador Frontend",
//   "url":"https://dcocoa.vercel.app/",
//   "img":"https://i.imgur.com/KkJOwK4.png"
// },