import React from 'react'
import { ContainerFooter } from './footer-styled/FooterStyled'
import logoPro from '../assets/img/logoPro.png'
import { useHistory } from 'react-router-dom'
import { RedirectAbout } from '../helpers/funtions'
const Footer = () => {
    const history = useHistory()

    const handleRedirectWebClick = ({target}) => {
        if(target.className === "fab fa-github"){
            window.open('https://github.com/jacksonguerrer0');
        }
        if(target.className === "fab fa-linkedin"){
            window.open('https://www.linkedin.com/in/jackson-guerrero/');
        }
        if(target.className === "fab fa-instagram"){
            window.open('https://www.instagram.com/jacksonguerrer0/');
        }
    }
    return (
        <ContainerFooter>
            <img 
            src={logoPro} 
            alt="Logo Jack" 
            onClick={() => {RedirectAbout(history)}}
            />
            <div className='containerIcon'>
                <i className="fab fa-github" onClick={handleRedirectWebClick}></i>
                <i className="fab fa-linkedin" onClick={handleRedirectWebClick} />
                <i className="fab fa-instagram" onClick={handleRedirectWebClick}></i>
            </div>
            <p className='pFooter'>© 2021 - Jackson Guerrero</p>
        </ContainerFooter>
    )
}

export default Footer
