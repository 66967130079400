import React from 'react'
import { ContainerAbout} from './about-style/AboutStyled'
import fotoJack from '../assets/img/fotoPerfilLinkedin.jpg'
import AboutAbilities from '../components/AboutAbilities'
const About = () => {
    return (
        <ContainerAbout>
            <div className='containerSentence'>
                <h2>"Si tu negocio no está en Internet, tu negocio no existe"</h2>
                <p className='authorSentence'>-Bill Gates</p> 
                <p className='descripSentence'>¿Qué sería de nosotros en pleno siglo XXI sin internet? Nuestra presencia en la red cada vez es mucho mayor.</p>
            </div>
            <div className='containerDescriptionMe'>
                <div>
                <h3>Hola a todos! Mi nombre es Jackson Guerrero</h3>
                <p>
                Soy Desarrollador FrontEnd y creo firmemente que el éxito de un negocio digital depende de todas las personas que la forman junto a la capacidad de usar sus conocimientos para una innovación. Mi enfoque es mejorar la rueda que ya esta creada, mejorando su rendimiento, calidad, satisfacción y dar un ¡Boom! en la sociedad. Disfruto impactando negocios a través de la tecnología, diseñando soluciones simples y útiles. Me apasiona la tecnologia, innovación y el deporte.</p>
                </div>
                <img src={fotoJack} alt="Jackson Guerrero" />
            </div>
            <div className='containerInfoHabilities'>
                <h3>¿Como puedo ayudarte?</h3>
                <p>Como profesional siempre estoy en constante aprendizaje y muy al tanto de nuevas tecnologias por ello puedes conocer lo que mayormente uso en tecnologias, habilidades y aptitudes que pueden ser utiles en tu negocio.</p>
                <AboutAbilities />
            </div>
        </ContainerAbout>
    )
}

export default About
