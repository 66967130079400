import styled from 'styled-components'
import { variables } from '../../variables-style/variables'

export const ContainerStudies = styled.main`
    margin: 2rem auto 0 auto;
    width: 90%;
    article{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 1rem;
        h2{
            margin: 2rem 0;
        }
        section{
            margin-top: 4rem;
            h3{
                margin-bottom: 1rem;
            }
            ul{
                margin-top: 1rem;
                li{
                    margin-left: 1rem;
                }
            }
        }
    }
    aside{
        width: 100%;
        max-width: 550px;
        margin: 6rem auto 2rem auto;
        .itemCarousel{
            cursor: pointer;
        }
        .thumbs-wrapper{
            display: none;
        }
        .carousel-status{
            display: none;
        }
        button{
            background-color: ${variables.background} !important;
        }
        .control-dots{
            display: none ;
        }
    }
    @media screen and (min-width: 700px){
        width: 80%;
        article{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            h2{
                grid-column: 1/3;
            }
        }
    }
    @media screen and (min-width: 1024px){
        width: 70%;
        article{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            h2{
                grid-column: 1/3;
            }
        }
    }
`