import styled from 'styled-components'
import { variables } from '../../variables-style/variables'

export const ContainerRating = styled.div`
    width: 50%;
    display: flex;
    height: 1rem;
    border-radius: 10rem;
    border: 2px solid ${variables.colorFontTwo};
    .divRating{
        width: ${(props) =>{
            console.log(props.rating * 20)
            return `${props.rating * 20}%`
        }};
        border-radius: 10rem;
        background-color: ${variables.colorFont};
    }
`