import styled from 'styled-components'
import { variables } from '../../variables-style/variables'

export const ContainerAbout = styled.div`
    display: grid;
    grid-template-columns: repeat(1, auto);
    width: 100%;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5rem;
    .containerSentence{
        width: 90%;
        margin: 0 auto;
        h2{
            text-align: center;
        }
        .authorSentence{
            text-align: right;
        }
        .descripSentence{
            color: ${variables.colorFontTwo};
            margin-top: 2rem;
            font-size: 0.8rem;
        }
    }
    .containerDescriptionMe{
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        margin-top: 5rem;
        div{
            padding: 1rem;
        }
        h3{
            text-align: center;
        }
        p{
            text-align: justify;
        }
        img{
            width: 50%;
            max-width: 300px;
            justify-self: center;
            order: -1;
            border-radius: 50%;
        }
    }
    .containerInfoHabilities{
        margin-top: 5rem;
        padding: 1rem;
    }
    @media screen and (min-width: 1024px){
        grid-template-columns: repeat(2, 1fr);
        max-width: 80%;
        .containerSentence{
            grid-column: 1/3;
            .descripSentence{
                text-align: center;
            }
        }
    }
`
