import styled, {keyframes} from 'styled-components'

const transition = keyframes`
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
`

export const Wrapper = styled.div`
    display:flex;
    flex-direction: column;
    align-items: center;
    margin: 2rem 0 5rem 0 ;
    .tab{
      cursor: pointer;
      width: calc(100%/3);
      padding: 0.4rem;
      height: inherit;
      display:inline-block;
      color:#fff;
      border-radius: 10rem;
      font-size: 4vw;
      text-align: center;
      z-index: 10;
    }
    .tabs{
      position: relative;
      display: flex;
      width: 100%;
      max-width: 400px;
      border-radius: 10rem;
      border: 2px solid #272628;
    }
    .hover-background{
      position: absolute;
      left: 0;
      top: 0;
      width: calc(100%/3);
      padding: 0.4rem;
      height: inherit;
      background-color: #272628;
      color: transparent;
      border-radius: 10rem;
      transition: transform 0.3s ease-in;
    }
    #one:checked ~ .tabs .hover-background{
      transform: translateX(0);
    }
    #two:checked ~ .tabs .hover-background{
      transform: translateX(100%);
    }
    #three:checked ~ .tabs .hover-background{
      transform: translateX(200%);
    }
    .panels{
        width:95%;
        max-width: 500px;
        border-radius:3px;
        overflow:hidden;
        padding: 20px 0 0 0;  
        display: block;
        overflow: auto;
    }
    .panel{
        display:none;
        animation: ${transition} .8s;
    }
    .panel-title{
    font-size:1rem;
    color: gray;
    padding: 0 1rem;
  }
  .radio{
    display:none;
  }
  #one:checked ~ .panels #one-panel,
  #two:checked ~ .panels #two-panel,
  #three:checked ~ .panels #three-panel{
    display:block
  }
  /* #one:checked ~ .tabs #one-tab,
  #two:checked ~ .tabs #two-tab,
  #three:checked ~ .tabs #three-tab{
    background:#272628;
    color:#ECECEC;
    /* border-top: 2px solid  #ECECEC; */
  
  .headerTab{
    margin: 0.5rem 0 !important;
  }
  
  .header-history{
    height: 6rem;
    border-radius: 0 0 1rem 1rem ;
    display: flex;
    align-items: center;
  }
  .header-history h2{
    text-align: center;
    margin: auto;
    color: white;
  }
.contentPanel{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.technologyTab{
  margin-top: 1rem !important;
}
@media screen and (min-width: 500px){
  .tab{
    font-size: 1rem;
  }
}
`