import React from 'react'
import { ContainerStudies } from './studies-style/StudiesStyled'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { RedirectToWork } from '../helpers/funtions';
import db from '../db.json'

const Studies = () => {
    return (
        <ContainerStudies>
            <article>
                <h2>La educación constante es lo necesario para aprovechar oportunidades.</h2>
                <section>
                    <h3>Academia Geek</h3>
                    <p>Bootcamp intensivo de desarrollo de software Front-End con duración de 220 horas. Abril-Julio 2021.
                    Metodología: aprender haciendo, basada en proyectos y en sprints, simulando retos reales del mundo laboral, 220 horas de formación técnica.
                    </p>
                    <ul>
                        <li>130 Técnicas</li>
                        <li>20 Design thinking</li>
                        <li>40 Habilidades del ser</li>
                        <li>30 empleabilidad</li>
                    </ul>
                </section>
                <section>
                    <h3>FrecodeCamp</h3>
                    <p>
                    Es una organización sin ánimo de lucro​ consistente en una plataforma web de enseñanza interactiva accesible para todo el mundo, una comunidad en línea de chats y diversas publicaciones y organizaciones locales que pretenden hacer de la enseñanza del desarrollo web algo accesible a todas las personas.
                    </p>
                </section>
            </article>
            <aside>
            <Carousel >
                {
                    db.studies.map(ele => (
                        <div key={ele.id} onClick={()=> {
                            RedirectToWork(ele.url)
                            }} className='itemCarousel'>
                            <img src={ele.img} alt={ele.fecha}/>
                        </div>
                    ))
                }
            </Carousel>
            </aside>
        </ContainerStudies>
    )
}

export default Studies
