import React from 'react'
import Particles from 'react-particles-js'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { createGlobalStyle } from 'styled-components'
import Footer from '../components/Footer'
import Nav from '../components/Nav'
import About from '../container/About'
import Contact from '../container/Contact'
import Studies from '../container/Studies'
import Work from '../container/Work'
import { variables } from '../variables-style/variables'

const GlobalStyle = createGlobalStyle`
    *{
        font-family: 'Noto Sans JP', sans-serif;
        font-size: ${variables.fontSize};
        color: ${variables.colorFont};
    }
    *,
    *::after,
    *::before{
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }
    body{
        background-color: ${variables.background}; 
    }
    p{
            margin-top: 2rem;
    }
    h2{
        font-size: 1.8rem;
    }
    h3{
        font-size: 1.3rem;
    }
    button{
        background-color: ${variables.colorFontTwo};
        border: none;
        padding: 0.5rem 1rem;
    }
`

const Routes = () => {
            return(
                <Router>
                <GlobalStyle />
                <Nav />
                <Switch>  
                    <Route exact path='/sobre' component={About}/>
                    <Route exact path='/contacto' component={Contact}/>
                    <Route exact path='/estudio' component={Studies}/>
                    <Route exact path='/trabajo' component={Work}/>
                    <Redirect to='/sobre' />
                </Switch>
                <div style={{
                position: "fixed",
                top: 0,
                bottom: 0,
                left: 0,
                width: "100%",
                height: '100vh',
                zIndex: '-1'
                }}>
                <Particles
                width='100vw' height="100vh"
                params={{
                "particles": {
                    "number": {
                        "value": 20
                    },
                    "size": {
                        "value": 2
                    }
                },
                "interactivity": {
                    "events": {
                        "onhover": {
                            "enable": true,
                            "mode": "repulse"
                        }
                    }
                }
                }} />
                </div>
                <Footer />
            </Router>
            )
        
}

export default Routes
